<template>
    <!--begin::Dashboard-->
    <div>
        <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
            <b-modal button-size="sm" id="description-modal" :title="$t('CLASS.DESCRIPTION.LABEL')">
                <nl2br v-if="classData.description" tag="p" :text="classData && classData.description"/>
                <template #modal-footer="{ok}">
                    <b-button variant="secondary" @click="ok()">{{ $t('CONTROLLERS.CLOSE') }}</b-button>
                </template>
            </b-modal>
            <b-modal button-size="sm" id="text_certificate-modal" :title="$t('CLASS.CERTIFICATE.LABEL')">
                <nl2br v-if="classData.text_certificate" tag="p" :text="classData && classData.text_certificate"/>
                <template #modal-footer="{ok}">
                    <b-button variant="secondary" @click="ok()">{{ $t('CONTROLLERS.CLOSE') }}</b-button>
                </template>
            </b-modal>
            <!-- Apercu de la classe en haut de la page de modification-->
            <div id="contener" class="d-flex flex-column">
                <div class="align-left mb-10 d-flex flex-row justify-content-between align-items-center">
                    <div style="width: 15%">
                        <router-link v-if="prevRoute" :to="prevRoute.fullPath" replace>
                            <span class="btn btn-outline-danger btn-md">
                                <span class="svg-icon svg-icon-md svg-icon-light-danger">
                                    <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                                </span>
                                {{ $t('CONTROLLERS.BACK') }}
                            </span>
                        </router-link>
                    </div>
                    <div class="d-flex flex-column align-items-center">
                        <h1 class="mb-1 h3 text-primary">
                            {{ $t('CLASS.NAME.LABEL') + classData.title }}
                        </h1>
                        <span class="text-muted font-weight-bold d-block">
                            {{
                                $t('CLASS.CREATED_AT', {
                                    date: formatDate(classData.created_at),
                                    name: classData.creator_name
                                })
                            }}
                        </span>
                    </div>
                    <div style="width:15%;text-align: end;"></div>
                </div>
                <div class="d-flex flex-wrap justify-content-between py-2" style="width: 100%">
                    <div style="max-width: 20%;height: 100%">
                        <img
                            v-if="classData"
                            :src="classData.miniature"
                            ref="EditMiniature"
                            alt="text"
                            style="max-width: 100%;max-height: auto; vertical-align:middle"
                            class="m-0 rounded"
                        />
                    </div>
                    <div id="primary" style="width: 50%;max-height: 20em"
                         class=" d-flex flex-column justify-content-between px-5">
                        <div id="description" style="height : 50%">
                            <span class="font-weight-bold">{{ $t('CLASS.DESCRIPTION.LABEL') }}</span>
                            <nl2br
                                v-if="classData.description"
                                tag="p"
                                :text="classData && classData.description"
                                class-name="customScroll mh-50 my-2 pl-2 border-secondary border-left nl2br"
                            />
                            <p v-b-modal.description-modal class="text-primary">
                                Voir la description complète
                            </p>
                        </div>
                        <div v-if="currentUser.role === 'admin'" id="text" style="height : 50%">
                            <span class="font-weight-bold">{{ $t('CLASS.CERTIFICATE.LABEL') }}</span>
                            <nl2br
                                v-if="classData.text_certificate"
                                tag="p"
                                :text="classData && classData.text_certificate"
                                class-name="customScroll my-2 pl-2 border-secondary border-left nl2br"
                            />
                            <p v-b-modal.text_certificate-modal class="text-primary">
                                Voir le texte complet
                            </p>
                            <b-button class="btn btn-info btn-sm mx-auto" hidden>{{
                                    $t('CLASS.DESCRIPTION.INPUT')
                                }}
                            </b-button>
                        </div>
                    </div>
                    <div class="d-flex flex-column justify-content-around px-2">
                        <div id="status">
                            <div v-if="currentUser.role === 'admin'" id="version">
                                <span class="font-weight-bold">{{ $t('CLASS.VERSION.LABEL') }}</span
                                >{{ classData.version }}
                            </div>
                            <div id="updateDate">
                                <span class="font-weight-bold">{{
                                        classData.updated_at != undefined ? $t('CLASS.UPDATED_AT.DEFINED') : $t('CLASS.UPDATED_AT.UNDEFINED')
                                    }}</span>
                                {{ classData.updated_at != undefined ? formatDate(classData.updated_at) : '' }}
                            </div>
                        </div>
                        <div id="on">
                            <span v-if="classData.on">
                                <span class="d-flex flex-row justify-content-between align-items-center">
                                    <span class="text-primary">{{ $t('CLASS.ON.ONLINE') }}</span>
                                </span>
                            </span>
                            <span v-else>
                                <span class="d-flex flex-row justify-content-between align-items-center">
                                    <span class="text-danger mr-2">{{ $t('CLASS.ON.OFFLINE') }}</span>
                                </span>
                            </span>
                            <b-modal
                                id="toogle_post"
                                header-text-variant="danger"
                                :title="classData.on ? $t('CLASS.ON.MODAL.TOOGLE_OFF_TITLE') : $t('CLASS.ON.MODAL.TOOGLE_ON_TITLE')"
                            >
                                <nl2br
                                    tag="p"
                                    v-if="classData.on"
                                    class="my-4"
                                    style="overflow:hidden"
                                    :text="$t('CLASS.ON.MODAL.TOOGLE_OFF_WARNING') + '\n\n' + $t('CONTROLLERS.CONFIRM')"
                                >
                                </nl2br>
                                <nl2br
                                    tag="p"
                                    v-else
                                    class="my-4"
                                    style="overflow:hidden"
                                    :text="$t('CLASS.ON.MODAL.TOOGLE_ON_WARNING') + '\n\n' + $t('CONTROLLERS.CONFIRM')"
                                >
                                </nl2br>
                                <template #modal-footer="{cancel}">
                                    <b-button @click="toogle_on()" variant="danger">
                                        {{ $t('CONTROLLERS.VALIDATION') }}
                                    </b-button>
                                    <b-button variant="secondary" @click="cancel()">
                                        {{ $t('CONTROLLERS.CANCEL') }}
                                    </b-button>
                                </template>
                            </b-modal>
                            <b-modal
                                id="toogle_post_fail"
                                header-text-variant="danger"
                                :title="
                                    classData.on ? 'Erreur rencontrée lors de la dépublication' : 'Erreur rencontrée lors de la publication'
                                "
                            >
                                <nl2br
                                    tag="p"
                                    v-if="classData && classData.on"
                                    class="my-4"
                                    style="overflow:hidden"
                                    :text="$t('CLASS.ON.MODAL.TOOGLE_OFF_FAIL')"
                                >
                                </nl2br>
                                <nl2br tag="p" v-else class="my-4" style="overflow:hidden"
                                       :text="$t('CLASS.ON.MODAL.TOOGLE_ON_FAIL')">
                                </nl2br>
                                <template #modal-footer="{ok}">
                                    <b-button variant="primary" @click="ok()">
                                        {{ $t('CONTROLLERS.BACK') }}
                                    </b-button>
                                </template>
                            </b-modal>
                        </div>
                        <div id="extend">
                            <span class="svg-icon" :class="classData.linear ? 'svg-icon-primary' : 'svg-icon-danger'">
                                <inline-svg src="/media/svg/icons/Design/Flatten.svg"/>
                                Parcours ordonné
                            </span>
                            <div id="objectif">
                                <span class="font-weight-bold">{{ $t('CLASS.GOAL.LABEL') }}</span>
                                {{ classData.goal === null ? 'Inderterminé' : classData.goal + '%' }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 p-5">
            <div class="d-flex flex-row justify-content-between align-items-center">
                <p class="h4 text-primary">
                    Stagiaires inscrits :
                </p>
            </div>
            <div
                class="border-0 m-0 d-flex flex-wrap align-items-center mt-5"
                :class="mobile ? 'justify-content-center' : 'justify-content-between'"
            >
                <div
                    class="border-0 m-0 d-flex flex-wrap align-items-center mb-5"
                    :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                    style="width:100%"
                >
                    <b-form-group :style="mobile ? 'width:100%' : 'width:40%'" class="m-0" label-for="filterInput">
                        <label for="filterInput">Nom du stagiaire</label>
                        <b-input-group>
                            <b-form-input
                                v-model="filterName"
                                type="search"
                                id="filterInput"
                                placeholder="Rechercher par Nom">
                            </b-form-input>
                            <b-input-group-append>
                                <b-button :disabled="!filterName" @click="filterName = ''">Effacer</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group v-if="customersList.length > 2"
                                  :style="mobile ? 'width:100%' : 'width:25%'"
                                  class="m-0"
                                  label="Établissements"
                                  label-for="filterInstitutionInput">
                        <b-form-select id="filterInstitutionInput" :options="customersList" v-model="filterCustomer">
                        </b-form-select>
                    </b-form-group>
                    <b-form-group
                        :label="flag === true? 'Sites Secondaires':''"
                        :style="mobile ? 'width:100%' : 'width:25%'"
                        class="m-0"
                        label-for="filterSSInput">
                        <b-form-select v-if="flag === true" id="filterSSInput" :options="secondary_siteList"
                                       v-model="filterSecondarySite">
                        </b-form-select>
                    </b-form-group>
                </div>
                <div class="w-100">
                    <div class="d-flex justify-content-end">
                        <b-button aria-label="Télécharger les attestations"
                                  class="btn btn-clean svg-icon svg-icon-primary mr-4"
                                  @click="getDocument()">
                            <inline-svg src="/media/svg/icons/General/Clipboard.svg"/>
                            Télécharger les attestations
                        </b-button>
                        <b-button aria-label="Exporter les résultats"
                                  variant="secondary" class="m-0 btn btn-clean ">
                            <vue-excel-xlsx
                                :data="fetchData()"
                                :columns="excelColumns"
                                :file-name="'Liste des stagiaires de la classe ' + classData.title"
                                :file-type="'xlsx'"
                                :sheet-name="'Liste des stagiaires'"
                            >
                                <img src="/media/png/icons/export/excel.png" width="20px"
                                     class="filter: invert(24%) sepia(89%) saturate(872%) hue-rotate(167deg) brightness(95%) contrast(95%);"
                                     alt="">
                                Exporter les résultats
                            </vue-excel-xlsx>
                        </b-button>
                    </div>
                    <b-modal ref="my-modal" centered no-close-on-backdrop hide-header-close hide-header hide-footer
                             title="loader">
                        <div class="d-block text-center">
                            <h3>Veuillez patientez</h3>
                        </div>
                        <div class="d-block text-center">
                            <b-spinner></b-spinner>
                        </div>
                    </b-modal>
                </div>
                <v-data-table
                    id="tableClasse"
                    :style="'width: 100%; font-family: Poppins,Helvetica;'" :loading-text="'Chargement...'"
                    :locale="'fr-FR'"
                    :items-per-page="perPage" :page="currentPage" :headers="headers"
                    :items="users.data && users.data.length === 0 ? [] : users.data"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    must-sort
                    responsive
                    :header-props="{ sortIcon: null }"
                    header-props.sort-icon="null"
                    @pagination="paginationMethod"
                    :loading="loading"
                    style="vertical-align: middle; text-align: center"
                    class="mx-0 p-0"
                    :class="mobile ? 'mb-5 mt-0' : 'my-5'"
                    hide-default-footer>

                    <template v-slot:header.user.secondary_site.site="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.user.customer.code="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.state.title="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.user.lastname="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.created_at="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.started_at="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.updated_at="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:header.score="{ header }">
                        {{ header.text }}
                        <v-icon style="margin-left: 5px">fas fa-sort</v-icon>
                    </template>
                    <template v-slot:item.user.customer.code="{ item }">
                        <div class="d-flex flex-column justify-content-around align-items-center"
                             v-if="item && item.user && item.customer">
                            {{ item.customer.name }}
                            <span class="text-muted"> ({{ item.customer.code }})</span>
                        </div>
                    </template>
                    <template v-slot:item.user.secondary_site.site="{ item }">
                        <div
                            class="d-flex flex-column justify-content-around align-items-center"
                            v-if="item && item.user && item.user.secondary_site">
                            {{ item.user.secondary_site.site }}
                        </div>
                    </template>

                    <template v-slot:item.created_at="{ item }">
                        {{ item.created_at ? formatDate(item.created_at) : '' }}
                    </template>
                    <template v-slot:item.started_at="{ item }">
                        {{ item.state.id === 2 || item.state.id === 3 ? formatDate(item.started_at) : '' }}
                    </template>

                    <template v-slot:item.updated_at="{ item }">
                        {{ item.state.id === 3 ? formatDate(item.updated_at) : '' }}
                    </template>
                    <template v-slot:item.state.title="{ item }">
                        <div>
                            <span v-if="item && item.state.id === 1">
                                {{ item.state.title }}
                            </span>
                            <span v-else-if="item.state.id === 2" class="text-primary">
                                {{ item.state.title }}
                            </span>
                            <span v-else-if="item.state.id === 3" class="text-success">
                                {{ item.state.title }}
                            </span>
                        </div>
                    </template>

                    <template v-slot:item.action="{ item }">
                        <div class="p-2">
                            <button
                                class="btn btn-clean btn-sm svg-icon svg-icon-primary"
                                v-if="item.state.id === 3"
                                @click="getDocument(item)"
                                v-b-tooltip.hover.left="'Récupérer l\'attestation'">
                                <inline-svg src="/media/svg/icons/General/Clipboard.svg"/>
                            </button>
                        </div>
                    </template>
                    <template v-slot:no-data>
                        <div v-if="!haveTrainees && !error">
                            <p class="text-primary">Vous n'avez aucun stagiaire associé à cette classe</p>
                            <router-link
                                :to="{ name: 'user/assign', params: { customerclasse: classData } }"
                                class="btn btn-sm btn-outline-primary btn-clean w-25 mt-2">
                                Associer un stagiaire
                            </router-link>
                        </div>
                        <div v-else-if="error">
                            <p class="text-primary">Nous sommes désolés, une erreur est survenue</p>
                        </div>
                    </template>
                    <template v-slot:no-results>
                        <div v-if="haveTrainees && !error">
                            <p class="text-primary">Aucun résultat pour cette recherche</p>
                        </div>
                        <div v-else-if="error">
                            <p class="text-primary">Nous sommes désolés, une erreur est survenue</p>
                        </div>
                    </template>
                </v-data-table>
                <b-row
                    class="d-flex align-items-center"
                    :class="mobile ? 'justify-content-center' : 'justify-content-between'"
                    style="width:100%"
                >
                    <b-col sm="5" md="4">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalRows"
                            :per-page="perPage"
                            align="fill"
                            aria-controls="tableClasse"
                        />
                    </b-col>
                    <div>
                        <ul class="pagination" :class="mobile ? 'mt-2' : ''">
                            <li class="page-item active">
                                <a class="page-link bg-primary">Nombre de résultats : {{ totalRows }}</a>
                            </li>
                        </ul>
                    </div>
                    <b-form-group
                        label="Nombre de résultats par page"
                        label-cols-sm="5"
                        label-cols-md="5"
                        label-cols-lg="5"
                        label-align-sm="center"
                        label-size="sm"
                        label-for="perPageSelect"
                        style="vertical-align : middle"
                        class="mr-2 align-items-center text-center"
                        :class="mobile ? 'text-center' : ''"
                    >
                        <b-form-select v-model="perPage" id="perPageSelect" size="sm"
                                       :options="pageOptions"></b-form-select>
                    </b-form-group>
                </b-row>
            </div>
        </div>
    </div>
    <!--end::Dashboard-->
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {LIST_CLASSE} from '@/core/services/store/api/classe.service';
import {LIST_USERS_MANAGE} from '@/core/services/store/api/classe_users.service';
import {mapGetters} from 'vuex';
import getDocument from '@/core/services/certificate.service.js';
import moment from 'moment';
import Nl2br from 'vue-nl2br';
import {LIST_CUSTOMER_ID} from "@/core/services/store/api/client.service";

export default {
    name: 'manage-classes',
    components: {
        Nl2br
    },
    async mounted() {
        await this.$store.dispatch(SET_BREADCRUMB, [{title: 'Classes Manager'}]);
        this.$store.dispatch(LIST_CUSTOMER_ID, this.$store.state.auth.user.customer === null ? this.$route.params.customerclasse.customer.id : this.$store.state.auth.user.customer.id).then(result => {
            result.data.forEach(customer => {
                this.customersList.push({
                    text: customer.name + ' (' + customer.code + ')',
                    value: customer.code
                });
            });
        });
        if (this.$route.params.customerclasse) {
            var customer;
            await this.$store.dispatch(LIST_CLASSE, this.$route.params.customerclasse.classe.id);
            if (this.$store.getters.currentUser.role === 'admin') {
                customer = await this.$route.params.customerclasse.customer.id;
            } else {
                customer = this.$store.getters.currentUser.customer.id
            }
            this.$store
                .dispatch(LIST_USERS_MANAGE, {
                    customerClasse: this.$route.params.customerclasse,
                    customerId: customer
                })
                .then(async result => {
                    if (result.data.length !== 0) {
                        this.error = false;
                        this.haveTrainees = result.data.data.length > 0;
                        this.perPage = 15;
                        this.totalRows = await this.users.data.length;
                        setTimeout(() => {
                            this.isBusy = false;
                        }, 2000);
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.isBusy = false;
                    this.error = true;
                    this.loading = false;
                });
        } else {
            await this.$router.push({name: 'admin/dashboard'});
        }
    },
    computed: {
        headers() {
            return [
                {
                    value: 'user.lastname',
                    text: 'Nom',
                    sortable: true,
                    filter: this.nameFilter,
                    class: 'my-header-style'
                },
                {value: 'user.firstname', text: 'Prénom', sortable: false},
                {value: 'user.customer.code', text: 'Établissement', sortable: true, filter: this.customersFilter},
                {
                    value: 'user.secondary_site.site',
                    text: 'Site secondaire',
                    sortable: true,
                    filter: this.secondary_siteFilter,
                    align: ' d-none'
                },
                {value: 'created_at', text: 'Inscrit le', sortable: true},
                {value: 'started_at', text: 'Commencé le', sortable: true},
                {value: 'updated_at', text: 'Terminé le', sortable: true},
                {value: 'state.title', text: 'Statut', sortable: true},
                {value: 'score', text: 'Résultat', sortable: true},
                {value: 'action', text: 'Attestation', sortable: false}
            ]
        },
        ...mapGetters({classData: 'getClasse', users: 'getUsersManage', userCustomers: 'getCustomersByIdOfUser'}),
        mobile() {
            return window.screen.availWidth <= 992;
        },
    },
    data() {
        return {
            fieldsLessons: [
                {
                    key: 'id',
                    label: '#',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'width: 50px'
                },
                {
                    key: 'title',
                    label: 'Titre',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 200px'
                },
                {
                    key: 'description',
                    label: 'Description',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 200px'
                },
                {
                    key: 'linear',
                    label: 'Cours ordonné',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 75px'
                },
                {
                    key: 'edit',
                    label: '',
                    tdClass: 'align-middle',
                    thClass: 'align-middle',
                    thStyle: 'min-width: 75px'
                }
            ],
            excelColumns: [
                {
                    label: 'Nom',
                    field: 'user.lastname',
                },
                {
                    label: 'Prénom',
                    field: 'user.firstname',
                },
                {
                    label: 'Établissement',
                    field: 'user.customer.name',
                },
                {
                    label: 'Inscrit le',
                    field: 'created_at',
                    dataFormat: this.formatDate
                },
                {
                    label: 'Commencé le',
                    field: 'started_at',
                    dataFormat: this.formatDate
                },
                {
                    label: 'Terminé le',
                    field: 'updated_at',
                    dataFormat: this.formatDate
                },
                {
                    label: 'Statut',
                    field: 'state.title'
                },
                {
                    label: 'Résultat',
                    field: 'score',
                    dataFormat: this.scoreFormat
                }
            ],
            prevRoute: null,
            currentUser: this.$store.getters.currentUser,
            show: true,
            totalRows: 0,
            haveTrainees: false,
            currentPage: 1,
            perPage: 15,
            pageOptions: [15, 30, 50, 100, {value: -1, text: 'Le plus possible'}],
            filterName: '',
            filterCustomer: '',
            filterSecondarySite: '',
            customersList: [{value: '', text: 'Tous les établissements'}],
            secondary_siteList: [{value: '', text: 'Tous les sites secondaire'}],
            isBusy: true,
            sortBy: 'user.lastname',
            error: false,
            sortDesc: false,
            flag: false,
            loading: true,
        };
    },
    methods: {
        scoreFormat(value) {
            if (value > 0) {
                return value + '%';
            } else {
                return '';
            }
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        fetchData() {
            if (this.users && this.users.data) {
                let users = this.users.data.filter(user => {
                    let filteredUsers = user.user.lastname.toLowerCase().includes(this.filterName.toLowerCase()) &&
                        user.customer.code.toLowerCase().includes(this.filterCustomer.toLowerCase())
                    if (user.user.secondary_site === null) {
                        filteredUsers = filteredUsers && true
                    } else {
                        filteredUsers = filteredUsers && user.user.secondary_site.site.toLowerCase().includes(this.filterSecondarySite.toLowerCase())
                    }
                    return filteredUsers
                })
                return users
            }

        },
        paginationMethod(pagination) {
            this.totalRows = pagination.itemsLength
        },
        /**
         * Filter for trainees names column.
         * @param value Value to be tested.
         * @returns {boolean}
         */
        nameFilter(value) {
            this.currentPage = 1;

            if (!value) {
                return true;
            }
            // If this filter has no value we just skip the entire filter.
            if (!this.filterName) {
                return true;
            }

            // Check if the current loop value
            // partially contains the searched word.
            return value.toLowerCase().includes(this.filterName.toLowerCase());
        },

        /**
         * Filter for trainees customer column.
         * @param value Value to be tested.
         * @returns {boolean}
         */
        customersFilter(value) {
            this.currentPage = 1;
            // If this filter has no value we just skip the entire filter.
            if (!this.filterCustomer) {
                return true;
            }
            // Check if the current loop value
            // equals to the selected value at the <v-select>.
            return value === this.filterCustomer;
        },
        secondary_siteFilter(value) {
            this.currentPage = 1;

            // If this filter has no value we just skip the entire filter.
            if (!this.filterSecondarySite) {
                return true;
            }

            // Check if the current loop value
            // equals to the selected value at the <v-select>.
            return value === this.filterSecondarySite;
        },
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        },
        getDocument(classeUser = null) {
            this.showModal();
            // si param = null
            if (!classeUser) {
                var filterCustomer = this.filterCustomer;
                var filterSecondarySite = this.filterSecondarySite;
                var filterName = this.filterName;
                var sucessUser = this.users.data.filter(function (elem) {
                    if ( (elem.state.id === 3) && (filterCustomer === '' || filterCustomer === elem.customer.code) && (filterSecondarySite === '' || filterSecondarySite === elem.user.secondary_site.site) && (filterName === '' || elem.user.lastname.toLowerCase().includes(filterName.toLowerCase())) ) {
                        return elem;
                    }
                });
                if (sucessUser.length > 0) {
                    getDocument(sucessUser).then(data => {
                        data.download(data.docDefinition.info.title);
                    }).finally(() => {
                        this.hideModal();
                    });
                } else {
                    this.hideModal();
                    //show toast
                    this.$bvToast.toast('Aucun utilisateur n\'a été trouvé', {
                        title: 'Aucun utilisateur',
                        variant: 'danger',
                        solid: true
                    });
                }
            } else {
                // sinon
                getDocument([classeUser]).then(data => {
                    data.download(data.docDefinition.info.title);
                }).finally(() => {
                    this.hideModal();
                });
            }
        }
    },
    beforeDestroy() {
        this.loading = true;
        this.users.data = [];
        this.haveTrainees = false;
        this.error = false;
    },
    watch: {
        perPage: function (val) {
            if (val === -1) {
                this.currentPage = 1
                this.perPage = this.totalRows
            }
        },
        users: function (val) {
            // Afficher ou non la colonne secondary_site si le client en a
            if (val.data)
                val.data.forEach(user => {
                    if (user && user.user && user.user.secondary_site) {
                        this.headers[3].align = "";
                        this.flag = true
                        var newItem = {
                            value: user.user.secondary_site.site,
                            text: user.user.secondary_site.site
                        }
                        var checkObj = this.secondary_siteList.findIndex(x => x.value === newItem.value);
                        checkObj === -1 ? this.secondary_siteList.push(newItem) : null;
                    }
                });
            if(this.flag === true) {
                //Ajouter au tableau this.excelColumns la colonne secondary_site en 4ème position
                this.excelColumns.splice(3, 0, {
                    label: 'Site secondaire',
                    field: 'user.secondary_site.site',
                });
            }
        }
    },
    updated() {
        if (this.filteredItems) {
            if (this.filteredItems.length === 0) {
                this.totalRows = this.classUsers.length;
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from;
        });
    }
};
</script>
<style>
.nl2br {
    max-height: 45%;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}


.nl2brLesson {
    max-height: 7em;
    overflow-y: scroll;
    text-align: left;
    padding-right: 2px;
}


.customScroll::-webkit-scrollbar-track {
    background-color: #eee;
}

.customScroll::-webkit-scrollbar {
    width: 5px;
    background-color: #eee;
}

.customScroll::-webkit-scrollbar-thumb {
    background-color: #0000005b;
}

.svg-icon svg {
    width:20px!important;
    height:20px!important;
}
</style>
